.swiperSelf {
  width: 100%;
  min-height: 55px;
  display: flex;
  align-items: center;
  position: relative;

  .swiper {
    width: 100%;
  }
  
  .swiper-wrapper-prev {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 42.5px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    background-color: rgba($color: #333, $alpha: 0.5);
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    cursor: pointer;
    z-index: 6;

    .prev-icon {
      color: #fff;
      font-weight: bold;
      font-size: 18px;
    }
  }

  .swiper-wrapper-next {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 42.5px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    background-color: rgba($color: #333, $alpha: 0.5);
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    cursor: pointer;
    z-index: 6;

    .next-icon {
      color: #fff;
      font-weight: bold;
      font-size: 20px;
    }
  }

  // SwiperSmall组件的切换按钮样式
  .swiper-small-wrapper-prev {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 35px;
    height: 55px;
    line-height: 55px;
    text-align: left;
    cursor: pointer;
    z-index: 6;

    .prev-icon {
      color: #fff;
      font-weight: bold;
      font-size: 18px;
    }
  }
  .swiper-small-wrapper-next {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 35px;
    height: 55px;
    line-height: 55px;
    text-align: right;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    z-index: 6;

    .next-icon {
      color: #fff;
      font-weight: bold;
      font-size: 20px;
    }
  }

  // SwiperSmall组件的切换按钮样式
  .swiper-controll-top {
    position: absolute;
    top: -30px;
    right: 10px;
    width: 70px;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .swiper-controll-top-prev {
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      border: 1px solid #dddddd;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
      z-index: 6;
  
      .prev-icon {
        color: #fff;
        font-weight: bold;
        font-size: 18px;
      }
    }
    .swiper-controll-top-next {
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      border: 1px solid #dddddd;
      line-height: 32px;
      text-align: center;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
      z-index: 6;
  
      .next-icon {
        color: #fff;
        font-weight: bold;
        font-size: 20px;
      }
    }
    .active {
      background: #D90B17;
      border: 1px solid #D90B17;
    }
  }
}

// swiper样式覆盖
.swiper-pagination {
  bottom: 15px !important;
}
.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  margin-right: 12px !important;
  border-radius: 50%;
  background-color: #b8b8b8 !important;
  opacity: 1 !important;
  cursor: pointer;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background-color: red !important;
}


// swiper hover展示切换箭头样式
.hover-show-arrows {
  .swiper-wrapper-prev {
    display: none;
  }
  .swiper-wrapper-next {
    display: none;
  }
}
.hover-show-arrows:hover {
  .swiper-wrapper-prev {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-wrapper-next {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}