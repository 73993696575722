
              .csicon {
                width: 1em;
                height: 1em;
                vertical-align: -0.15em;
                fill: currentColor;
                overflow: hidden;
              }
            @font-face {
    font-family: cs-icons;
    src: url("cs-icons.eot");src: url("cs-icons.eot?268607de#iefix") format("embedded-opentype"),
             url("cs-icons.woff2?268607de") format("woff2"),
             url("cs-icons.woff?268607de") format("woff"),
             url("cs-icons.ttf?268607de") format("truetype"),
             url("cs-icons.svg?268607de#cs-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}

.iconfont {
    font-family: 'cs-icons' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
}


.csicon-category-109::before {
    position:relative;
content: "\ea01";
}

.csicon-category-147::before {
    position:relative;
content: "\ea02";
}

.csicon-category-2107::before {
    position:relative;
content: "\ea03";
}

.csicon-category-258::before {
    position:relative;
content: "\ea04";
}

.csicon-category-2957::before {
    position:relative;
content: "\ea05";
}

.csicon-category-2959::before {
    position:relative;
content: "\ea06";
}

.csicon-category-2975::before {
    position:relative;
content: "\ea07";
}

.csicon-category-334::before {
    position:relative;
content: "\ea08";
}

.csicon-category-35::before {
    position:relative;
content: "\ea09";
}

.csicon-category-3657::before {
    position:relative;
content: "\ea0a";
}

.csicon-category-3781::before {
    position:relative;
content: "\ea0b";
}

.csicon-category-4::before {
    position:relative;
content: "\ea0c";
}

.csicon-category-50::before {
    position:relative;
content: "\ea0d";
}

.csicon-category-57::before {
    position:relative;
content: "\ea0e";
}

.csicon-category-62::before {
    position:relative;
content: "\ea0f";
}

.csicon-category-65::before {
    position:relative;
content: "\ea10";
}

.csicon-category-70::before {
    position:relative;
content: "\ea11";
}

.csicon-category-73::before {
    position:relative;
content: "\ea12";
}

.csicon-category-991::before {
    position:relative;
content: "\ea13";
}

.csicon-Address-Manager::before {
    position:relative;
content: "\ea14";
}

.csicon-Contact::before {
    position:relative;
content: "\ea15";
}

.csicon-Credits::before {
    position:relative;
content: "\ea16";
}

.csicon-Help-Centre::before {
    position:relative;
content: "\ea17";
}

.csicon-Login-Details::before {
    position:relative;
content: "\ea18";
}

.csicon-My-Status::before {
    position:relative;
content: "\ea19";
}

.csicon-My-Wishlist::before {
    position:relative;
content: "\ea1a";
}

.csicon-Order-History::before {
    position:relative;
content: "\ea1b";
}

.csicon-addtowishlist-full::before {
    position:relative;
content: "\ea1c";
}

.csicon-addtowishlist-hollow::before {
    position:relative;
content: "\ea1d";
}

.csicon-app::before {
    position:relative;
content: "\ea1e";
}

.csicon-search::before {
    position:relative;
content: "\ea1f";
}

.csicon-shopping-cart::before {
    position:relative;
content: "\ea20";
}

.csicon-star-full::before {
    position:relative;
content: "\ea21";
}

.csicon-star-hollow::before {
    position:relative;
content: "\ea22";
}

.csicon-top-bt::before {
    position:relative;
content: "\ea23";
}

.csicon-user::before {
    position:relative;
content: "\ea24";
}

.csicon-wishlist::before {
    position:relative;
content: "\ea25";
}

.csicon-service1-color::before {
    position:relative;
content: "\ea26";
}

.csicon-service2-color::before {
    position:relative;
content: "\ea27";
}

.csicon-service3-color::before {
    position:relative;
content: "\ea28";
}

.csicon-service4-color::before {
    position:relative;
content: "\ea29";
}

.csicon-service5-color::before {
    position:relative;
content: "\ea2a";
}

.csicon-service6-color::before {
    position:relative;
content: "\ea2b";
}

.csicon-service7-color::before {
    position:relative;
content: "\ea2c";
}

.csicon-share-tiktok-color::before {
    position:relative;
content: "\ea2d";
}

.csicon-social-bg-color::before {
    position:relative;
content: "\ea2e";
}

.csicon-social-dg-color::before {
    position:relative;
content: "\ea2f";
}

.csicon-social-email-color::before {
    position:relative;
content: "\ea30";
}

.csicon-social-fb-color::before {
    position:relative;
content: "\ea31";
}

.csicon-social-g+-color::before {
    position:relative;
content: "\ea32";
}

.csicon-social-ins-color::before {
    position:relative;
content: "\ea33";
}

.csicon-social-lk-color::before {
    position:relative;
content: "\ea34";
}

.csicon-social-pin-color::before {
    position:relative;
content: "\ea35";
}

.csicon-social-tw-color::before {
    position:relative;
content: "\ea36";
}

.csicon-social-ytb-color::before {
    position:relative;
content: "\ea37";
}

