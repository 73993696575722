@import './_variables.scss';
@import './_margin.scss';
@import './_padding.scss';

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: Helvetica,Arial,sans-serif, ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: #ffffff;
  background: #F5F5F5;
  font-family: Arial,Helvetica,sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.wapper{
  width: 100%;
  max-width: 1444px;
  margin: 0 auto;
  padding-top: 12px;
}

// 浏览器自动填充的背景色，覆盖为白色
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

@media screen and (max-width: 1560px) and (min-width: 1024px){
  .wapper {
    width: calc(100% - 120px);
  }
}

@media #{$media-query-small} {
    .wapper {
      padding-right: 20px;
    }
}


// Ant-design 的一些样式覆盖（主题编辑器无法调整的部分）
.ant-btn {
  box-shadow: none !important;
}
.ant-btn-primary {
  box-shadow: none !important;
}
.ant-select-arrow {
  color: #333 !important;
  font-size: 14px !important;
}

.ant-space-item {
  align-self: flex-start !important;
}

.ant-input-status-error {
  border: 1px solid #D70B17 !important;
}

.ant-input-affix-wrapper-status-error {
  border: 1px solid #D70B17 !important;
}

.ant-form-item-explain-error {
  padding-top: 4px;
  padding-bottom: 12px;
}

/* 自定义 message 样式 */
.ant-message {
  font-size: 18px; /* 设置字体大小 */
}

/* 自定义 message 内容的字体大小 */
.ant-message-notice-content {
  font-size: 18px; /* 自定义字体大小 */
  font-weight: bold;
}

/* 自定义图标大小 */
.ant-message-custom-content .anticon {
  font-size: 24px; /* 设置图标大小 */
}