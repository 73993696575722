/*padding px 为准*/

.no-padding {
  padding: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.padding-5 {
  padding: 10px !important;
}

.padding-6 {
  padding: 6px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-12 {
  padding: 12px !important;
}

.padding-16 {
  padding: 16px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-30 {
  padding: 30px !important;
}

.padding-40 {
  padding: 40px !important;
}

.padding-50 {
  padding: 50px !important;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}

.padding-left-30 {
  padding-left: 30px !important;
}

.padding-left-36{
  padding-left: 36px;
}

.padding-left-40 {
  padding-left: 40px !important;
}

.padding-left-50 {
  padding-left: 50px !important;
}

.padding-top-2 {
  padding-top: 2px !important;
}

.padding-top-4 {
  padding-top: 4px !important;
}

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-top-6 {
  padding-top: 6px !important;
}

.padding-top-8 {
  padding-top: 8px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-12 {
  padding-top: 12px !important;
}

.padding-top-14 {
  padding-top: 14px !important;
}

.padding-top-16 {
  padding-top: 16px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-top-24 {
  padding-top: 24px !important;
}

.padding-top-26 {
  padding-top: 26px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.padding-top-40 {
  padding-top: 40px !important;
}

.padding-top-50 {
  padding-top: 50px !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-right-20 {
  padding-right: 20px !important;
}

.padding-right-30 {
  padding-right: 30px !important;
}

.padding-right-40 {
  padding-right: 40px !important;
}

.padding-right-50 {
  padding-right: 50px !important;
}

.padding-bottom-5 {
  padding-bottom: 5px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-bottom-12 {
  padding-bottom: 12px !important;
}

.padding-bottom-16 {
  padding-bottom: 16px !important;
}

.padding-bottom-18 {
  padding-bottom: 18px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-bottom-24 {
  padding-bottom: 24px !important;
}

.padding-bottom-30 {
  padding-bottom: 30px !important;
}

.padding-bottom-40 {
  padding-bottom: 40px !important;
}

.padding-bottom-50 {
  padding-bottom: 50px !important;
}

.padding-bottom-60 {
  padding-bottom: 60px !important;
}

.padding-bottom-80 {
  padding-bottom: 80px !important;
}

.padding-bottom-100 {
  padding-bottom: 100px !important;
}
