/*margin px 为准*/

.no-margin {
  margin: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.margin-5 {
  margin: 10px !important;
}

.margin-10 {
  margin: 10px !important;
}

.margin-16 {
  margin: 16px !important;
}

.margin-20 {
  margin: 20px !important;
}

.margin-30 {
  margin: 30px !important;
}

.margin-40 {
  margin: 40px !important;
}

.margin-50 {
  margin: 50px !important;
}

.margin-left-4 {
  margin-left: 4px !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-left-6 {
  margin-left: 6px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-12{
  margin-left: 12px !important;
}

.margin-left-14 {
  margin-left: 14px !important;
}

.margin-left-16 {
  margin-left: 16px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-left-30 {
  margin-left: 30px !important;
}

.margin-left-40 {
  margin-left: 40px !important;
}

.margin-left-50 {
  margin-left: 50px !important;
}

.margin-left-60 {
  margin-left: 60px !important;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.margin-top-2 {
  margin-top: 2px !important;
}

.margin-top-4 {
  margin-top: 4px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-6{
  margin-top: 6px !important;
}

.margin-top-8{
  margin-top: 8px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-12 {
  margin-top: 12px !important;
}

.margin-top-16 {
  margin-top: 16px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-24 {
  margin-top: 24px !important;
}

.margin-top-26 {
  margin-top: 26px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-32 {
  margin-top: 32px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-left-8{
  margin-left: 8px;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-right-16{
  margin-right: 16px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-right-30 {
  margin-right: 30px !important;
}

.margin-right-40 {
  margin-right: 40px !important;
}

.margin-right-50 {
  margin-right: 50px !important;
}

.margin-right-60 {
  margin-right: 60px !important;
}

.margin-bottom-4{
  margin-bottom: 4px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-6 {
  margin-bottom: 6px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-12 {
  margin-bottom: 12px !important;
}

.margin-bottom-14 {
  margin-bottom: 14px !important;
}

.margin-bottom-16 {
  margin-bottom: 16px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-24 {
  margin-bottom: 24px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.margin-bottom-60 {
  margin-bottom: 60px !important;
}

.margin-bottom-80 {
  margin-bottom: 80px !important;
}
